<template>
  <div class="container home d-flex flex-column justify-content-center">
    <HomeJumbotron/>
    <div class="card-deck">
      <HomeCard v-for="(site, index) in sites" :key="index" v-bind="site"/>
    </div>
  </div>
</template>

<script>
import HomeJumbotron from '@/components/home/HomeJumbotron.vue';
import HomeCard from '@/components/home/HomeCard.vue';
import sites from '@/data/sites.json';

export default {
  name: 'home',
  components: {
    HomeJumbotron,
    HomeCard,
  },
  data() {
    return {
      sites,
    };
  },
};
</script>

<style scoped>
.home {
  min-height: calc(100vh - 72px - 10rem);
}
</style>
