<template>
  <nav class="navbar navbar-light navbar-expand d-flex flex-column flex-md-row align-items-center p-3 px-md-4 shadow-sm">
    <router-link class="navbar-brand my-0 font-weight-normal" to="/">KASSET FINTECH</router-link>
    <ul class="navbar-nav ml-md-auto">
      <router-link class="nav-item nav-link" active-class="active" exact="" to="/">Home</router-link>
      <router-link class="nav-item nav-link" active-class="active" exact="" to="/about">About</router-link>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'AppNavbar',
};
</script>
