<template>
  <div class="card mb-4 shadow-sm">
    <!-- <img :src="tech.imageUrl" class="card-img-top" alt="..."> -->
    <div class="card-body">
      <h5 class="card-title">{{ name }}</h5>
      <p class="card-text">{{ url }}</p>
      <a class="btn btn-primary btn-block" :href="url" :title="url" target="_blank">이동하기</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCard',
  props: {
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.home-header {
  max-width: 48rem;
}
.card-deck .card {
  min-width: 14rem;
}
.card-img-top {
  object-fit: contain;
}

</style>
