<template>
  <div class="container">
    <footer class="app-footer border-top pt-5">
      <div class="row">
        <div class="col-12">
          <p class="mb-0">KOREA ASSET INVESTMENT SECURITIES CO.,LTD.</p>
          <small class="d-block text-muted">© 2019-2020</small>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.app-footer {
  height: 10rem;
}
</style>
