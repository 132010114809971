<template>
  <div class="container about my-5">
    <h3 class="mb-4">Map</h3>
    <div id="map" class="w-100"></div>
  </div>
</template>

<script>
export default {
  name: 'About',
  mounted() {
    const kakao = window.kakao || null;
    if (kakao) {
      const container = document.getElementById('map');
      const markerPosition = new kakao.maps.LatLng(37.523014, 126.925630);
      const options = {
        center: markerPosition,
        level: 3,
        scrollwheel: true,
      };
      const map = new kakao.maps.Map(container, options);

      const marker = new kakao.maps.Marker({
        position: markerPosition,
      });
      marker.setMap(map);

      const zoomControl = new kakao.maps.ZoomControl();
      map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
    }
  },
};
</script>

<style scoped>
#map {
  height: 25rem;
}
</style>
